<template>
  <div>
    <!-- <div class="colorbg">
      <div class="left">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{
            type == 1 ? "购物" : type == 2 ? "服务" : "信息"
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="firstName">{{
            firstName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="twoNmae">{{ twoNmae }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="right">
        <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" style="width: 200px" clearable
          @change="getGoodeList" v-model="query.searchValue">
        </el-input>

        <div class="paixu">
          <div class="li">
            <el-button type="primary" :plain="query.sortField == 'distance' ? false : true" size="small"
              icon="el-icon-s-promotion" @click="changeSort('distance')">距离远近
              <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'distance'"></i>
              <i class="el-icon-bottom el-icon--right" v-if="
                query.sortOrder == 'desc' && query.sortField == 'distance'
              "></i>
            </el-button>
          </div>
          <div class="li">
            <el-button type="primary" :plain="query.sortField == 'collectNum' ? false : true" size="small"
              @click="changeSort('collectNum')" icon="el-icon-s-flag">收藏人数
              <i class="el-icon-top el-icon--right" v-if="
                query.sortOrder == 'asc' && query.sortField == 'collectNum'
              "></i>
              <i class="el-icon-bottom el-icon--right" v-if="
                query.sortOrder == 'desc' && query.sortField == 'collectNum'
              "></i>
            </el-button>
          </div>
          <div class="li">
            <el-button type="primary" :plain="query.sortField == 'days' ? false : true" size="small"
              @click="changeSort('days')" icon="el-icon-s-custom">入驻时间
              <i class="el-icon-top el-icon--right" v-if="query.sortOrder == 'asc' && query.sortField == 'days'"></i>
              <i class="el-icon-bottom el-icon--right" v-if="query.sortOrder == 'desc' && query.sortField == 'days'"></i>
            </el-button>
          </div>
        </div>
      </div>
    </div> -->

    <div class="fenlei">
      <div class="listBox">
        <div class="list" @click="changeType(item, index)" v-for="(item, index) in leftList" :key="index">
          <el-dropdown>
            <span class="el-dropdown-link">
              <div class="babaFlex" :class="yijiInv == index ? 'active' : ''">
                <img :src="item.icon" alt="" />
                <span>{{ item.classifyName }}</span>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown" v-if="item.children">
              <el-dropdown-item v-for="(item2, index2) in item.children" :key="index2">
                <div class="xialafelx" @click="changeType2(item, item2, index2, index)">
                  <img :src="item2.icon" alt="" />
                  <span>{{ item2.classifyName }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="goodslist">


      <div class="goods" :class="list.length == 0 ? 'goodsNone' : ''">
        <el-empty description="暂无数据" v-if="list.length == 0"></el-empty>
        <div class="lists" v-for="(item, index) in list" :key="index">
          <div class="list">
            <div class="flex">
              <div class="left" style="margin-right: 15px">
                <el-image style="width: 170px; height: 170px" :src="item.infoPic" fit="cover"></el-image>
                <div class="checkIcons">
                  <div class="list" @click.stop="dianzan(item)">
                    <div>{{ item.likeNum ? item.likeNum : 0 }}</div>
                    <i class="iconfont" v-if="item.likeStatus == 0">&#xec7f;</i>
                    <i class="iconfont" v-else style="color: red;">&#xec7f;</i>
                  </div>
                  <div class="list"  @click.stop="shoucang(item)">
                    <div>{{ item.collectNum }}</div>
                    <i class="iconfont" v-if="item.collectStatus == 0">&#xe612;</i>
                    <i class="iconfont" v-else style="color: red;">&#xe612;</i>
                  </div>
                  <div class="list" @click.stop="toInfo2(item)">
                    <div>{{ item.commentNum }}</div>
                    <i class="iconfont">&#xe641;</i>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="name">
                  {{ item.infoTitle }}
                </div>
                <div class="desc">
                  <span>距离：{{ item.userDistance }} 千米</span>
                </div>
                <!-- <div class="desc">
                  <div class="shocuangIcon" @click.stop="shoucang(item)">
                    收藏：【{{ item.collectNum }}人收藏】
                    <img src="../../../assets/shoucang.png" alt="" v-if="item.collectStatus == 0" />
                    <img src="../../../assets/shoucang_a.png" alt="" v-if="item.collectStatus == 1" />
                  </div>
                </div> -->
                <!-- <div class="tousu" @click.stop="showTs(item)">投诉</div> -->
                <div class="desc">
                  <span>主体：{{ item.infoMainName }}[{{ item.days }}天]</span>
                </div>
              </div>
            </div>

            <div class="desc" style="
                                margin-top: 10px;
                                display: flex;
                                justify-content: space-between;
                              ">
              <el-popover style="width: 30%" placement="top-start" title="电话" trigger="hover" :content="item.linkPhone">
                <div class="btnName" slot="reference" style="width: 100%">
                  <i class="el-icon-phone-outline"></i>
                  电话
                </div>
              </el-popover>

              <div class="btnName" @click.stop="showTs(item)" style="width: 30%">
                <i class="el-icon-s-flag"></i>
                投诉
              </div>

              <div class="btnName" slot="reference" @click.stop="toInfo2(item)" style="width: 30%"
                v-if="item.fixType == 3">
                <span>查看详情</span>
              </div>
              <div class="btnName" slot="reference" v-else @click.stop="toInfo(item)" style="width: 30%">
                <span v-if="item.business.length > 0">{{
                  item.business[0].name
                }}</span>
                <span v-else>查看详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fenye" v-if="list.length > 0">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChange"
        :page-size="query.pageSize">
      </el-pagination>
    </div>
    <el-dialog title="投诉" :visible.sync="dialogVisible" width="30%">
      <div class="lits">
        <span>标题：{{ tousuInfo.infoTitle }}</span>
      </div>
      <div class="lits">
        <span>编码：{{ tousuInfo.infoCode }}</span>
      </div>
      <div class="lits">
        <span>联系方式：{{ tousuInfo.linkPhone }}</span>
      </div>
      <!-- <div class="lits"><span>投诉时间：2022-01-11 15:12</span></div> -->
      <div class="lits"><span>投诉详情：</span></div>
      <div>
        <el-input type="textarea" :rows="5" placeholder="请输入投诉详情" v-model="tousuForm.content">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="suretousu">确 定</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import {
  classifybuyList,
  classifyneighborList,
  classifyinfoList,
  neighborInfolist,
  neighborInfounCollect,
  neighborInfocollect,
  neighborShoplist,
  neighborServelist,
  neighborShopunCollect,
  neighborShopcollect,
  neighborServecollect,
  neighborServeunCollect,
  neighborInfolike,
  neighborInfounLike,
  complaintadd,
} from "@/request/api.js";
export default {
  name: "goodslist",
  data() {
    return {
      tousuForm: {
        infoId: "",
        content: "",
      },
      yijiInv: 0,
      smallInv: -1,
      textarea: "",
      dialogVisible: false,
      list: [],
      input2: "",
      leftList: [],
      global: this.GLOBAL,
      firstName: "",
      twoNmae: "",
      total: 0,
      tousuInfo: {},
      query: {
        pageSize: 15,
        pageNum: 1,
        longitude: "",
        latitude: "",
        searchValue: "",
        classify: "",
        sortField: "distance",
        sortOrder: "asc",
        fixType: '',
        collectStatus: "",
      },
    };
  },

  watch: {
    "$store.state.type"(val, oldVal) {
      if (val) {
        console.log(val);
        this.type = val;
        this.query.searchValue = "";
        this.query.sortField = "distance";
        this.query.sortOrder = "asc";
        this.getleftData();
        this.getGoodeList();
      }
    },
    "$store.state.lng"(val, oldVal) {
      this.query.searchValue = "";
      this.query.sortField = "distance";
      this.query.sortOrder = "asc";

      if (this.$store.state.lat) {
        this.query.latitude = this.$store.state.lat;
        this.query.longitude = this.$store.state.lng;
      }
      this.getleftData();
      this.getGoodeList();
    },
  },
  created() {
    this.type = this.$store.state.type;
    this.getleftData();


  },
  methods: {
    dianzan(item) {
				if (item.likeStatus == 0) {
					neighborInfolike({
						infoId: item.infoId
					}).then(res => {
            this.$message.success('点赞成功');
            this.getGoodeList();

					});
				} else {
					neighborInfounLike({
						infoId: item.infoId
					}).then(res => {
						this.$message.success('取消成功');
            this.getGoodeList();

					});
				}
			},
    init(e) {
      this.query.collectStatus = e;
      this.getGoodeList();
      let data = {
        collectStatus: 1,
      };

      classifybuyList(data).then((res) => {

        if (res.data.length > 0) {
          res.data.forEach(item => {
            if (item.children.length > 0) {
              item.showchildren = true
            } else {
              item.showchildren = false
            }
          })
        }

        this.leftList = res.data;

      });
    },
    showTs(e) {
      this.tousuInfo = e;
      this.tousuForm.infoId = e.infoId;
      console.log(e);
      this.dialogVisible = true;
    },
    changeType(e, index) {
      this.yijiInv = index
      console.log(e);
      this.query.classify = e.id;
      this.firstName = e.classifyName;
      this.twoNmae = "";
      this.getGoodeList();
    },
    changeType2(item, item2, index2, index) {
      item.smallInv = index2;
      this.query.classify = item2.id;
      this.firstName = item.classifyName;
      this.twoNmae = item2.classifyName;
      this.getGoodeList();
      this.$refs.mergePopover[index].doClose();
    },

    getGoodeList() {
      // if (this.type == 1) {
      //   this.query.fixType = 2;
      // } else if (this.type == 2) {
      //   this.query.fixType = 1;
      // } else {
      //   this.query.fixType = 3;
      // }

      neighborInfolist(this.query).then((res) => {
        console.log("信息列表");
        console.log(res);
        this.list = res.rows;
        this.total = res.total;
      });
    },
    toGooleMap(item) {
      let form = this.$store.state.place;
      let lat = item.latitude;
      let lng = item.longitude;
      window.open(`http://www.google.com/maps/dir/${form}/${lat},${lng}`);
    },
    getleftData(type) {
      this.firstName = this.twoNmae = "";

      let data = {
        collectStatus: type ? "1" : "",
      };

      classifybuyList(data).then((res) => {
        if (res.data.length > 0) {
          res.data.forEach(item => {
            if (item.children.length > 0) {
              item.showchildren = true
            } else {
              item.showchildren = false
            }
          })
        }
        this.leftList = res.data;

        if (this.leftList.length > 0) {
          this.query.classify = this.leftList[0].id

        }
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position);
          console.log(this.$store);
          console.log(this.$store);
          console.log(this.$store.state);
          if (this.$store.state.lat) {
            this.query.latitude = this.$store.state.lat;
            this.query.longitude = this.$store.state.lng;
          } else {
            this.query.latitude = position.coords.latitude;
            this.query.longitude = position.coords.longitude;
          }

          this.getGoodeList();
        });
      });
    },
    toInfo(item) {
      if (item.business.length > 0) {
        let link = item.business[0].link;

        window.open(link);
      } else {
        this.$router.push({
          path: `/infomationInfo3?id=${item.infoId}`,
        });
      }
    },
    toInfo2(item) {
      this.$router.push({
        path: `/infomationInfo3?id=${item.infoId}`,
      });
    },
    changeSort(e) {
      if (this.query.sortField == e) {
        if (this.query.sortOrder == "asc") {
          this.query.sortOrder = "desc";
        } else {
          this.query.sortOrder = "asc";
        }
      } else {
        this.query.sortField = e;
      }

      this.getGoodeList();
    },
    currentChange(e) {
      this.query.pageNum = e;
      this.getGoodeList();
    },
    suretousu() {
      if (this.tousuForm.content == "") {
        return this.$message.warning("请输入投诉详情");
      }

      complaintadd(this.tousuForm).then((res) => {
        this.$notify({
          title: "成功",
          message: "投诉成功",
          type: "success",
        });
        this.tousuForm.content = "";

        this.dialogVisible = false;
      });
    },
    shoucang(item) {
      console.log(item);
      let inv = this.$store.state.type;
      this.xinxiShoucang(item.infoId, item.collectStatus);
    },
    xinxiShoucang(id, type) {
      if (type == 0) {
        //收藏
        neighborInfocollect({ infoId: id }).then((res) => {
          this.$message.success("收藏成功");

          this.getGoodeList();
        });
      } else {
        //取消收藏
        neighborInfounCollect({ infoId: id }).then((res) => {
          this.$message.success("取消成功");

          this.getGoodeList();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tousu {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
  z-index: 10;
}

.lits {
  margin-bottom: 10px;
}

.colorbg {
  width: 70%;
  margin: 0 auto;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 200px;
  box-sizing: border-box;

  .right {
    display: flex;
    align-items: center;
  }

  .paixu {
    display: flex;
    align-items: center;

    .li {
      display: flex;
      align-items: center;
      margin-left: 20px;
      font-size: 15px;
      cursor: pointer;
      color: #757474;

      img {
        width: 18px;
        margin-left: 4px;
      }
    }
  }
}

.hasNone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  color: #787777;
}

.fenye {
  width: 70%;
  margin: 0 auto;
  padding-left: 190px;
  box-sizing: border-box;
}

.btnName {
  display: flex;
  align-items: center;
  font-size: 13px;
  border: 1px solid #e70012;
  border-radius: 3px;
  padding: 6px 0;
  justify-content: center;
  color: #e70012;

  i {
    margin-right: 4px;
  }
}

.erji {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;

  .li {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 5px;
    }
  }
}

.fenlei {
  margin: 20px 0;

  .listBox {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: 0 auto;
    width: 90%;
    justify-content: space-between;

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 15px;
    }
  }

}

.babaFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  img {
    height: 50px;
    width: auto;
  }
}

.active {
  color: #ff6464;
}

.xialafelx {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    margin-right: 5px;
  }
}

.goodslist {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  padding-bottom: 20px;
  box-sizing: border-box;



  .goods {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .goodsNone {
    justify-content: center;
    min-height: 500px;
    display: flex;
  }


  .lists {
    margin-bottom: 10px;
    width: 19%;
    display: inline-block;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    background: #e6e6e6;
    height: 240px;
    margin-left: 5px;

    .list {
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;

      .flex {
        display: flex;
      }

      .mobile {
        display: flex;
        justify-content: flex-end;
        height: 50px;
        align-items: center;
        border-top: 1px dashed #ccc;

        .lus {
          display: flex;
          align-items: center;
          margin-left: 60px;
          color: #5e5e5e;

          img {
            width: 25px;
            margin-right: 10px;
          }
        }
      }

      .left {
        position: relative;

        img {
          width: 160px;
          margin-right: 20px;
          border-radius: 10px;
          height: 188px;
        }

        .checkIcons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: rgba(0, 0, 0, 0.28);
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 50px;

          .list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            font-size: 14px;
          }

          img {
            width: 18px;
            height: auto;
            margin-right: 0;
            border-radius: none;
          }
        }


      }

      .right {
        width: 100%;
        position: relative;

        .name {
          font-size: 18px;
          margin-bottom: 10px;
          color: #a80404;
          font-weight: bold;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .desc {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #5e5e5e;
          font-size: 14px;

          .shocuangIcon {
            display: flex;
            align-items: center;

            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.goodsNone::after {
  content: "";
  width: 0;
}


/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
div ::-webkit-scrollbar {
  width: 0px !important;
  height: 7px;
}

/* 滚动槽 */
div ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
}

/* 滚动条滑块 */
div::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5) !important;
}

div::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4) !important;
}

.activesmall {
  color: red;
}
</style>
